<template>
  <div class="section section-tickets-list component">
    <!-- TODO: delete this whole file? UNUSED -->
    <div class="section-body">
    <div class="head">
      <div class="title">{{ $t('title.ticketsList') }}</div>
      <div class="subtitle"></div>
    </div>
    <div class="main">
      <router-link :to="{ name: 'create-ticket', params:{ eventId: this.$route.params.eventId } }">
        <span>{{ $t('button.addTicket') }}</span>
      </router-link>
      <div v-if="hasTickets()" class="tickets-list">
        <mu-card
            class="ticket-item"
            v-for="ticket in tickets"
            :key="ticket.id"
            @click="viewTicket($event, ticket.id)"
        >
          <div class="ticket-tr-corner-actions">
            <div class="item">
              <mu-button fab small color="red" @click.prevent="confirmDeleteTicket($event, ticket.id)">
                <mu-icon value="remove"></mu-icon>
              </mu-button>

              <mu-dialog :title="$t('events.tickets.delete.confirmDeletion')" width="300" :open.sync="deleteTicketConfirmIsOpen">
                <mu-button slot="actions" color="grey500" @click="cancelDeleteTicket()">{{ $t('button.cancel') }}</mu-button>
                <mu-button slot="actions" color="primary" @click="deleteTicket($event, ticket.id)">{{ $t('button.delete') }}</mu-button>
              </mu-dialog>
            </div>
          </div>
          <mu-card-media title="" sub-title="">
            <img :src="ticket.file_url">
          </mu-card-media>
          <mu-card-title
              v-if="ticket.title"
              :title="ticket.title"
          ></mu-card-title>
          <mu-card-text>
            <span v-if="ticket.date_start" class="ticket-dates">{{ ticket.date_start }}<span v-if="ticket.date_end"> — {{ ticket.date_end }}</span></span>
          </mu-card-text>
        </mu-card>
      </div>
      <div v-else class="tickets-list-empty">
        <div class="title">{{ $t('events.tickets.noTickets') }}</div>
        <div class="actions">
          <router-link
              :to="{ name: 'create-ticket', params:{ eventId: this.$route.params.eventId } }">
            <span>{{ $t('button.addTicket') }}</span>
          </router-link>
        </div>
      </div>
    </div>
      <div class="push"></div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'event-tickets',
  components: {},
  computed: {
    ...mapState(
      "ticketsStore", ["ticketsList"]
    )
    // ...mapState({
    // 	eventInfo: state => state.eventStore.eventInfo,
    // }),
  },
  watch: {
    ticketsList: {
      deep: true,
      handler() {
        this.setTicketsList()
      }
    }
  },
  data: () => ({
    edit: false,
    current: 1,
    personal: {},
    tickets: [],
    deleteTicketConfirmIsOpen: false,
    ticketToDelete: {
      "event" : null,
      "ticket_id" : null
    }
  }),
  created() {
    this.$store.dispatch('ticketsStore/callTicketsList', this.$route.params.eventId);
  },
  updated() {

  },
  methods: {
    ...mapActions(
      "ticketsStore", ["deleteTicket"]
    ),

    clearTicketToDelete(){
      this.ticketToDelete.event = null;
      this.ticketToDelete.ticket_id = null;
    },

    confirmDeleteTicket(event, ticket_id){
      event.preventDefault();
      event.stopPropagation();

      this.ticketToDelete.event = event;
      this.ticketToDelete.ticket_id = ticket_id;

      this.deleteTicketConfirmIsOpen = true;
    },

    setTicketsList() {
      this.tickets = this.ticketsList.List;
    },

    hasTickets() {
      return this.tickets.length > 0;
    },

    viewTicket(event, ticket_id) {
      this.$router.push({
        name: 'view-ticket',
        params: {
          eventId: this.$route.params.eventId,
          id_ticket: ticket_id
        }
      });
    },

    deleteSuccessHandler() {
      this.$store.dispatch('ticketsStore/callTicketsList', this.$route.params.eventId);
      this.clearTicketToDelete();
    },

    deleteFailHandler() {
      // TODO
    },

    deleteTicket(event, ticket_id) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if(ticket_id){
        ticket_id = this.ticketToDelete.ticket_id;
      }

      this.deleteTicketConfirmIsOpen = false;
      this.$store
        .dispatch('ticketsStore/deleteTicket', ticket_id)
        .then(this.deleteSuccessHandler, this.deleteFailHandler);
    },

    cancelDeleteTicket(){
      this.clearTicketToDelete();
      this.deleteTicketConfirmIsOpen = false;
    }

  }
}
</script>

<style scoped lang="scss">

  @mixin templ {
    display: flex;
    margin: 10px 0;
    font-size: 14px;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0, 0, 0, 0.58);

  }

  .tickets-list,
  .tickets-list-empty {
    padding-top: 3rem;
  }

  .footer-nav,
  .push {
    height: 50px;
  }

  .footer-nav {
    position: fixed;
    bottom: 0;
  }

  .tickets-list {
    text-align: left;
  }

  .ticket-item {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    margin: 0 10px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
    max-width: 250px;
    text-decoration: none;

    .ticket-image,
    .mu-card-media {
      max-height: 250px;
      overflow: hidden;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .ticket-tr-corner-actions {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;

      > .item {
        float: right;
        margin-left: 10px;
      }
    }
  }

</style>
